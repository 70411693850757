import React from "react"
import styled from "styled-components"
import parse from "html-react-parser"
import AnimatedImage from "../global/AnimatedImage"
import FakeButtonModal from "./FakeButtonModal"
import ButtonExternal from "./ButtonExternal"
import Button from "./Button"
import ButtonSnipcart from "./ButtonSnipcart"

const FlexibleImageText = styled.section`
  width: 100%;
  position: relative;
  margin-bottom: 8rem;

  &.lessMarginBottom {
    margin-bottom: 0rem;
  }

  .wrapper {
    @media only screen and (min-width: 1024px) {
      display: grid;
      grid-template-columns: 1fr 4rem 1fr;
    }
  }

  .text-wrap {
    margin-bottom: 2rem;

    @media only screen and (min-width: 1024px) {
      margin-bottom: 0;
    }
  }

  .text-wrap,
  .image {
    /* width:50%; */
    position: relative;
    display: block;
    height: 100%;
    grid-row-start: 1;
  }

  .heading--l {
    margin-bottom: 2rem;
  }

  .text {
    margin-bottom: 2rem;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: var(--black);
      font-family: var(--font-bold);
      margin-bottom: 2rem;
    }

    ul li,
    p {
      line-height: 1.5rem;
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      color: var(--black);
      font-family: var(--font-bold);
    }
  }

  .image {
    .gatsby-image-wrapper {
      /* max-height: 400px; */
    }
  }

  .tlir {
    .text-wrap {
      /* order:1; */
      /* padding-right:4rem; */
      grid-column-start: 1;
      grid-column-end: 2;
    }

    .image {
      /* order:2; */
      grid-column-start: 3;
      grid-column-end: 4;
    }
  }
  .iltr {
    .text-wrap {
      /* order:2;
        padding-left:4rem; */
      grid-column-start: 3;
      grid-column-end: 4;
    }

    .image {
      /* order:1; */
      grid-column-start: 1;
      grid-column-end: 2;
    }
  }
`

export default function FlexImageText({
  alignment,
  backgroundColour,
  buttonLink,
  buttonText,
  buttonType,
  heading,
  snipcart,
  text,
  url,
  image,
  buttonPageLink,
  finalButtonPageLinkIgnore,
  lessMarginBottom,
}) {
  let finalButtonPageLink
  if (finalButtonPageLinkIgnore !== true) {
    if (buttonPageLink?.__typename === "WpPost") {
      finalButtonPageLink = `/${buttonPageLink.categories.nodes[0].slug}/${buttonPageLink.slug}`
    }
    if (buttonPageLink?.__typename === "WpPage") {
      finalButtonPageLink = `/${buttonPageLink.slug}`
    }
    if (buttonPageLink?.__typename === "WpListing") {
      finalButtonPageLink = `/independents/${buttonPageLink.slug}`
    }
  } else {
    finalButtonPageLink = buttonPageLink
  }

  return (
    <FlexibleImageText
      className={lessMarginBottom === true ? "lessMarginBottom" : ""}
    >
      <div className={alignment + " wrapper"}>
        <div className="text-wrap">
          {heading ? <h2 className="heading--l">{heading}</h2> : ""}
          <div className="text">{parse(text)}</div>

          {buttonType === "external" && buttonText !== null ? (
            <ButtonExternal
              color="black"
              bg={"black"}
              text={buttonText}
              link={buttonLink}
            />
          ) : buttonType === "internal" && buttonText !== null ? (
            <Button
              color="black"
              text={buttonText}
              bg={"black"}
              link={finalButtonPageLink}
            />
          ) : buttonType === "snipcart" && buttonText !== null ? (
            <ButtonSnipcart
              itemId={snipcart?.itemId}
              itemPrice={snipcart?.itemPrice}
              itemName={snipcart?.itemName}
              isSubscription={snipcart?.isSubscription}
              subscriptionCycle={snipcart?.subscriptionCycle}
              isShippable={snipcart?.isShippable}
              isTaxable={snipcart?.isTaxable}
              url={url}
              color="black"
              bg="brandColor"
            />
          ) : buttonType === "launchContact" ? (
            <FakeButtonModal color="black" text="Contact us" withHover={true} />
          ) : (
            ""
          )}
        </div>
        <div className="image">
          <AnimatedImage
            imgSrc={image?.localFile?.childImageSharp?.gatsbyImageData}
            offsetX={"1rem"}
            offsetY={"1rem"}
            color={"brandColor"}
          />
        </div>
      </div>
    </FlexibleImageText>
  )
}
