import React from 'react'
import styled from 'styled-components';
import AnimatedImage from './AnimatedImage'
import Button from './Button';

const ImageThreeCol = styled.section`
margin:0 0 4rem 0;

@media only screen and (min-width: 1024px) {
margin:0 0 8rem 0;
}



    .wrapper{
    display:flex;
    flex-wrap:wrap;

        .column{
        width:100%;
        margin-bottom:4rem;

            &:last-child{
            margin-bottom:0;
            }

            @media only screen and (min-width: 1024px) {
            width:calc(33.33% - 3rem);
            margin-right:4rem;
            margin-bottom:0;
            }

            &:last-of-type{
            margin-right:0;
            }

            .image-wrapper{
            position:relative;
            margin-bottom:3rem;
            padding-bottom:56.25%;
            
                @media only screen and (min-width: 600px) {
                    padding-bottom:30%;
                }
                @media only screen and (min-width: 1024px) {
                    padding-bottom:56.25%;
                }
            }

            .heading--l{
            margin-bottom:1rem;
            }
            
            .text{
            line-height:1.5rem;
            margin-bottom:1rem;
            }


        }
    }
`

export default function ImageTextThreeColumns({columnsData}) {
    return (
        <ImageThreeCol>
            <div className="wrapper">
                {
                    columnsData.map((column, index) => {
                        return(
                            <article className="column" key={column.title+"_"+index}>
                                <AnimatedImage
                                    imgSrc={column.image.localFile.childImageSharp.gatsbyImageData}
                                    offsetX={'1rem'}
                                    offsetY={'1rem'}
                                    color={'brandColor'}
                                />

                                <h3 className="heading--l">{column.title}</h3>
                                <div className="text">{column.text}</div>
                                {column.buttonText ?
                                <Button
                                    color="black"
                                    bg="black"
                                    text={column.buttonText}
                                    link={column.buttonLink}
                                />
                                : ""}
                            </article>
                        )
                    })
                }
            </div>
        </ImageThreeCol>
    )
}
