import React, { useEffect } from "react"
import styled from "styled-components"
import { fbq } from "@hutsoninc/gatsby-plugin-facebook-pixel"

import Arrow from "../../assets/button-arrow.inline.svg"

const ButtonStyled = styled.div`
  position: relative;
  z-index: 10;
  display: inline-flex;
  width: auto;
  flex-wrap: wrap;

  button {
    text-decoration: none;
    padding: 10px 15px;
    margin: 0;
    font-family: var(--font-reg);
    transition: 0.5s all ease-in-out;
    border: 1px solid var(${props => props.color});
    color: var(${props => props.color});
    overflow: hidden;
    position: relative;
    cursor: pointer;

    &:after {
      content: "";
      position: absolute;
      top: 0px;
      /* top:-10px; */
      left: 0;
      width: 100%;
      height: 100%;
      /* height:calc(100% + 20px); */
      transform: translateX(-100%);
      background: var(${props => props.bg});
      transition: 0.5s all ease-in-out;
      z-index: -1;
    }

    span {
      z-index: 1;
      position: relative;
    }

    svg {
      margin-left: 2rem;

      path {
        transition: 0.5s all ease-in-out;
        fill: var(${props => props.color});
      }
    }

    &:hover {
      color: var(${props => props.invert});
      border: 1px solid var(${props => props.bg});

      svg path {
        fill: var(${props => props.invert});
      }
      &:after {
        transform: translateX(0%);
      }
    }
  }
`

export default function ButtonSnipcart(data) {
  useEffect(() => {
    if (typeof window !== "undefined") {
      const { Snipcart } = window
      if (!Snipcart) return
      // open modal on snipcart event add item on cart
      Snipcart.events.on("item.adding", _item => {
        fbq("track", "AddToCart", {
          content_name: _item.name,
          content_ids: _item.id,
          content_type: "product",
          value: _item.price,
          currency: "GBP",
        })
      })
    }
  }, [])
  //   console.log(data)
  return (
    <ButtonStyled
      color={"--" + data.color}
      bg={data.bg ? "--" + data.bg : "--white"}
      invert={data.color === "white" ? "--black" : "--white"}
    >
      {data.isSubscription === true ? (
        <button
          className="snipcart-add-item"
          data-item-id={data.itemId.replace(/\s+/g, "-").toLowerCase()}
          // data-item-price={data.itemPrice.toFixed(2)}
          data-item-url={`https://${process.env.GATSBY_SITENAME.toLowerCase()}.independentlife.co.uk${
            data.url
          }`}
          data-item-price={data.itemPrice.toFixed(2)}
          data-item-name={data.itemName}
          data-item-recurring-shipping={data.isShippable}
          data-item-taxable={data.isTaxable}
          data-item-payment-interval={data.subscriptionCycle}
          data-item-description={data.itemName}
          data-item-selected-plan={`${data.itemId
            .replace(/\s+/g, "-")
            .toLowerCase()}-plan`}
          data-plan1-name={data.itemName}
          data-plan1-frequency={data.subscriptionCycle}
          data-plan1-count={data.subscriptionDuration}
          data-plan1-interval="1"
          data-plan1-id={`${data.itemId
            .replace(/\s+/g, "-")
            .toLowerCase()}-plan`}
          data-item-plan1-price={data.itemPrice.toFixed(2)}
        >
          Buy {data.itemName} now
        </button>
      ) : (
        <button
          className="snipcart-add-item"
          data-item-id={data.itemId}
          data-item-price={data.itemPrice.toFixed(2)}
          data-item-url={`https://${process.env.GATSBY_SITENAME.toLowerCase()}.independentlife.co.uk${
            data.url ? data.url : "/"
          }`}
          data-item-name={data.itemName}
          data-item-shippable={data.isShippable === true ? true : false}
          data-item-taxable={data.isTaxable === true ? true : false}
        >
          Buy {data.itemName} now
        </button>
      )}
    </ButtonStyled>
  )
}
