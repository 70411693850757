import React from "react"
import HeroDefault from "../components/global/HeroDefault"
import styled from "styled-components"
import FlexImageText from "../components/global/FlexImageText"
import TestimonialsTest from "../components/advertise/Testimonials"
import ImageTextThreeColumns from "../components/global/ImageText3Columns"
import CaseStudySlider from "../components/global/CaseStudySlider"
import { graphql } from "gatsby"
import FormCTA from "../components/global/FormCTA"
import Seo from "../components/seo"

const FlexWrapper = styled.div`
  section:first-of-type {
    margin-bottom: 4rem;

    @media only screen and (min-width: 1024px) {
      margin-bottom: 8rem;
    }
  }

  section:first-of-type:not(.heroDefault) {
    padding-top: 4rem;
  }

  .spacer {
    margin-bottom: 8rem;
  }

  /* section:last-of-type{
margin-bottom:0;
} */

  section {
    background: var(--white);
    position: relative;
  }

  .caseStudySlider {
    /* background:var(--grey); */

    &:after {
      z-index: 0;
    }
  }

  .propertiesFinder {
    position: relative;
    color: var(--white);
    background: var(--teal) !important;
    padding: 2rem 0 4rem 0;
    /* margin-top:4rem; */
    margin-bottom: 4rem;

    @media only screen and (min-width: 1024px) {
      padding: 4rem 0;
      /* margin-top:8rem; */
      margin-bottom: 8rem;
    }

    section {
      background: var(--teal) !important;
      padding-top: 1rem !important;

      @media only screen and (min-width: 1024px) {
        margin-bottom: 4rem;
        padding-top: 4rem !important;
      }
    }

    .text {
      line-height: 1.5rem;
    }

    .button {
      border: 1px solid var(--white);
      color: var(--white);

      svg {
        path {
          fill: var(--white);
        }
      }
    }
  }

  .testimonials-wrapper {
    /* background:var(--grey); */

    &:before {
      background: var(--white);
    }

    .quote {
      path {
        fill: var(--teal);
      }
    }
  }

  /* &:before{
content:"";
height:35%;
background:var(--grey);
position:absolute;
bottom:0;
width:100%;
} */
`

const FlexiblePage = ({ data }) => {
  const flex = data.flexible.acf_flexbuilder.flexiblePageBuilder
  const seo = data.seo.seo
  const title = data.flexible.title

  return (
    <FlexWrapper>
      <Seo
        title={seo.title}
        description={seo.metaDesc}
        image={
          title === "Property Services"
            ? data.og.options.acf_options.ogPropertyServices.mediaItemUrl
            : title === "Place Strategy"
            ? data.og.options.acf_options.ogPlaceStrategy.mediaItemUrl
            : title === "Place Creation"
            ? data.og.options.acf_options.ogPlaceCreation.mediaItemUrl
            : ""
        }
      />
      {flex.map((flexItem, index) => {
        {
          /* console.log(flexItem.fieldGroupName) */
        }
        if (
          flexItem.fieldGroupName.includes(
            "AcfFlexbuilder_FlexiblePageBuilder_IntroBlock"
          )
        ) {
          return (
            <HeroDefault
              key={flexItem.heading + index}
              title={flexItem.heading}
              text={flexItem.text}
              buttonText={flexItem.buttonText}
              buttonType={flexItem.buttonType}
              buttonLink={flexItem.buttonLink}
              backgroundImage={flexItem.image}
              backgroundColor={flexItem.backgroundColour}
            />
          )
        }
        if (
          flexItem.fieldGroupName.includes(
            "WpPage_AcfFlexbuilder_FlexiblePageBuilder_ImageText3Column"
          )
        ) {
          return (
            <ImageTextThreeColumns
              key={"3Cols" + index}
              columnsData={flexItem.imageText3Column}
            />
          )
        }
        if (
          flexItem.fieldGroupName.includes(
            "WpPage_AcfFlexbuilder_FlexiblePageBuilder_PropertyFinder"
          )
        ) {
          return (
            <section className="propertiesFinder">
              <FlexImageText
                key={"propFinder" + index}
                alignment={"iltr"}
                backgroundColour={flexItem.imageBackgroundColour}
                buttonLink={"/properties-finder"}
                buttonText={"View properties"}
                buttonType={"internal"}
                heading={"Properties Finder"}
                text={
                  data.propertyFinderOptions.options.acf_options
                    .propertyFinderText
                }
                fieldGroupName={flexItem.fieldGroupName}
                image={
                  data.propertyFinderOptions.options.acf_options
                    .propertyFinderImage
                }
                buttonPageLink={"/properties-finder"}
                finalButtonPageLinkIgnore={true}
              />
            </section>
          )
        }
        if (
          flexItem.fieldGroupName.includes(
            "WpPage_AcfFlexbuilder_FlexiblePageBuilder_TestimonialSlider"
          )
        ) {
          return <TestimonialsTest testimonials={flexItem.testimonials} />
        }
        if (
          flexItem.fieldGroupName.includes(
            "AcfFlexbuilder_FlexiblePageBuilder_ImageTextBlock"
          )
        ) {
          // console.log(flexItem);
          return (
            <FlexImageText
              key={flexItem.heading + index}
              alignment={flexItem.alignment}
              backgroundColour={flexItem.imageBackgroundColour}
              buttonLink={flexItem.buttonLink}
              buttonText={flexItem.buttonText}
              buttonType={flexItem.buttonType}
              snipcart={flexItem.snipcartFields}
              url={data.seo.seo.opengraphUrl}
              heading={flexItem.heading}
              text={flexItem.text}
              fieldGroupName={flexItem.fieldGroupName}
              image={flexItem.image}
              buttonPageLink={flexItem.buttonPageLink}
              finalButtonPageLinkIgnore={false}
            />
          )
        }

        if (
          flexItem.fieldGroupName.includes(
            "AcfFlexbuilder_FlexiblePageBuilder_FormCallToAction"
          )
        ) {
          return (
            <FormCTA
              key={flexItem.ctaHeading + index}
              ctaHeading={flexItem.ctaHeading}
              ctaText={flexItem.ctaText}
              formHeading={flexItem.formHeading}
              formName={flexItem.formName}
              formBuilder={flexItem.formBuilder}
              formButtonText={flexItem.formButtonText}
              sectionHeading={flexItem.sectionHeading}
              // bgSize="120%"
              // bgColor="grey"
              // bgPosition="bottom"
            />
          )
        }

        if (
          flexItem.fieldGroupName.includes(
            "AcfFlexbuilder_FlexiblePageBuilder_CaseStudiesSlider"
          )
        ) {
          return (
            <CaseStudySlider
              sectionTitle="Case Studies"
              linkPrefix={""}
              data={flexItem.caseStudies}
              bgPosition="bottom"
              bgSize="50%"
              bgColor="grey"
            />
          )
        }

        if (
          flexItem.fieldGroupName.includes(
            "AcfFlexbuilder_FlexiblePageBuilder_Spacer"
          )
        ) {
          return <div className="spacer"></div>
        }
      })}
    </FlexWrapper>
  )
}

export default FlexiblePage

export const pageQuery = graphql`
  query FlexiblePageById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
  ) {
    og: wp {
      options {
        acf_options {
          ogIndependentsMapOffers {
            mediaItemUrl
          }
          ogPlaceCreation {
            mediaItemUrl
          }
          ogPlaceStrategy {
            mediaItemUrl
          }
          ogPropertyServices {
            mediaItemUrl
          }
        }
      }
    }
    seo: wpPage(id: { eq: $id }) {
      seo {
        metaDesc
        title
        metaRobotsNofollow
        metaRobotsNoindex
        metaKeywords
        opengraphAuthor
        opengraphDescription
        opengraphImage {
          localFile {
            id
          }
        }
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphUrl
        twitterTitle
        twitterDescription
      }
    }

    propertyFinderOptions: wp {
      options {
        acf_options {
          propertyFinderImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          propertyFinderText
        }
      }
    }

    flexible: wpPage(id: { eq: $id }) {
      id
      title
      acf_flexbuilder {
        flexiblePageBuilder {
          ... on WpPage_AcfFlexbuilder_FlexiblePageBuilder_IntroBlock {
            backgroundColour
            buttonLink
            buttonText
            buttonType
            heading
            text
            fieldGroupName
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }

          ... on WpPage_AcfFlexbuilder_FlexiblePageBuilder_Spacer {
            fieldGroupName
          }

          ... on WpPage_AcfFlexbuilder_FlexiblePageBuilder_CaseStudiesSlider {
            fieldGroupName
            caseStudies {
              chooseACaseStudy {
                ... on WpCase_study {
                  slug
                  acf_caseStudyFields {
                    logo {
                      localFile {
                        childImageSharp {
                          gatsbyImageData
                        }
                      }
                    }
                    backgroundImage {
                      localFile {
                        childImageSharp {
                          gatsbyImageData
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          ... on WpPage_AcfFlexbuilder_FlexiblePageBuilder_FormCallToAction {
            ctaHeading
            ctaText
            fieldGroupName
            formName
            formBuilder {
              label
              typeFieldType
              options {
                option
              }
            }
            formButtonText
            formHeading
            sectionHeading
          }

          ... on WpPage_AcfFlexbuilder_FlexiblePageBuilder_ImageText3Column {
            fieldGroupName
            imageText3Column {
              title
              text
              buttonText
              buttonLink
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }

          ... on WpPage_AcfFlexbuilder_FlexiblePageBuilder_PropertyFinder {
            fieldGroupName
            showPropertyFinder
          }

          ... on WpPage_AcfFlexbuilder_FlexiblePageBuilder_TestimonialSlider {
            fieldGroupName
            testimonials {
              company
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              logo {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              name
              testimony
            }
          }

          ... on WpPage_AcfFlexbuilder_FlexiblePageBuilder_ImageTextBlock {
            fieldGroupName
            alignment
            buttonLink
            buttonText
            buttonType
            heading
            imageBackgroundColour
            text
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            snipcartFields {
              isSubscription
              itemId
              itemName
              itemPrice
              subscriptionCycle
              subscriptionDuration
              isShippable
              isTaxable
            }
            buttonPageLink {
              ... on WpPost {
                slug
                categories {
                  nodes {
                    slug
                  }
                }
              }
              ... on WpListing {
                slug
              }
              ... on WpPage {
                slug
              }
            }
          }
        }
      }
    }
  }
`
